import React from "react";
import { useEffect } from "react";
import { redirectTo } from "@reach/router";
// import { Redirect } from "@reach/router";

const NotFoundPage: React.FC = () => {
  useEffect(() => {
    redirectTo("/");
  }, []);
  return null;
};

// const NotFoundPage: React.FC = () => <Redirect to="/" />;

export default NotFoundPage;
